<template>
  <el-popover
    placement="bottom"
    :width="162"
    popper-class="history-popover"
    trigger="click"
    :visible-arrow="false"
    :append-to-body="false"
    :visible = "show"
    @hide="$emit('close')"
  >
    <div class="team-container">
      <!-- <div class="team-header">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">分组</el-checkbox>
        <span class="check-count">{{checkedTeamIds.length}}/{{teamNameList.length}}</span>
      </div> -->
      <div class="team-content">
        <el-checkbox-group v-model="checkedTeamIds" v-if="teamNameList.length > 0">
          <div class="item" v-for="item in teamNameList" :key="item.team_id">
            <el-checkbox :indeterminate="ishalf(item)" @change="(val) => handleChange(item, val)" :label="item.team_id" :title="item.team_name">{{item.team_name}}</el-checkbox>
          </div>
        </el-checkbox-group>
        <div v-else class="no-data">{{$t('nodata')}}</div>
      </div>
      <div class="team-footer" v-if="teamNameList.length > 0">
        <span class="btn" @click="show = false">{{$t('dialog.cancel')}}</span>
        <span class="btn" @click="handleSubmit">{{$t('dialog.confirm')}}</span>
      </div>
    </div>
    <template #reference>
      <span :class="['share-btn', { disable }]" @click="shareTeam">
        <template v-if="checkedTeamIds.length === 0">
          <span>{{$t('taskhub.history.share')}}</span>
          <el-icon class="el-icon--right" :class="{active:show}"><ArrowDown/></el-icon>
        </template>
        <template v-else>
          <el-tag
            v-for="item in teamNameTags"
            @close="handleClose(item.team_id)"
            :key="item.team_id"
            size="mini"
            closable
            disable-transitions
            :class="{hasSibling: checkedTeamIds.length > 1}"
          >{{item.team_name}}</el-tag>
          <el-tag
            v-if="checkedTeamIds.length > 1"
            size="mini"
            disable-transitions
            >+{{checkedTeamIds.length - 1}}</el-tag>
          <el-icon class="el-icon--right"><ArrowDown/></el-icon>
        </template>
      </span>
    </template>
  </el-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import { ArrowDown } from '@element-plus/icons-vue'
import { t } from 'app/i18n'

export default {
  name: 'Popover',
  components: {
    ArrowDown
  },
  props: {
    disable: Boolean,
    teamIdsOfTask: Object,
    checklist: Array
  },
  data () {
    return {
      checkAll: false,
      checkedTeamIds: [],
      isIndeterminate: false,
      show: false,
      half_ids: []
    }
  },
  computed: {
    ...mapGetters({
      teamList: 'team/teamList'
    }),
    teamNameList () {
      return this.teamList.map(item => ({ team_name: item.team_name, team_id: item.team_id }))
    },
    teamNameTags () {
      return this.teamList.filter(item => this.checkedTeamIds.includes(item.team_id)).slice(0, 1)
    }
  },
  watch: {
    checkedTeamIds (value) {
      // this.handleCheckedChange(value)
    },
    teamIdsOfTask: {
      handler: function (value) {
        // const teamListIds = this.teamList.map(item => item.team_id)
      // if (value.length > 0) {
      //   this.checkedTeamIds = value.filter(item => teamListIds.includes(item))
      // } else {
      //   this.checkedTeamIds = []
      // }
        const arr = []
        for (const key in value) {
          arr.push(value[key])
        }
        const flatArr = Array.from(new Set(arr.flat()))

        let minArr = []
        const fullArr = []
        arr.forEach(i => {
          minArr = i
          if (i.length < minArr.length) {
            minArr = i
          }
        })
        minArr.forEach(i => {
          const state = []
          arr.forEach(j => {
            if (j.includes(i)) {
              state.push(true)
            } else state.push(false)
          })
          const status = state.every(v => {
            return v === true
          })
          status && !fullArr.includes(i) && fullArr.push(i)
        })
        this.half_ids = flatArr.filter(v => {
          return !fullArr.includes(v)
        })
        this.checkedTeamIds = fullArr
      },
      deep: true
    }

  },
  methods: {
    shareTeam() {
      if (!this.checklist.length) {
        this.$store.commit('app/showMessage', { type: 'warning', message: t('taskhub.history.share_warn'), duration: 800 })
      } else {
        this.show = true
      }
    },
    ishalf(item) {
      return this.half_ids.includes(item.team_id)
    },
    clear () {
      this.checkedTeamIds = []
      this.isIndeterminate = false
    },
    handleCheckAllChange (val) {
      this.half_ids = []
      this.checkedTeamIds = val ? this.teamNameList.map(item => item.team_id) : []
      this.isIndeterminate = false
    },
    handleChange(item, val) {
      this.$emit('choose', item, val)
    },
    handleCheckedChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.teamNameList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.teamNameList.length
    },
    handleHide () {

    },
    handleSubmit () {
      const rest = this.teamNameList.map(v => v.team_id).filter(v => !this.checkedTeamIds.includes(v))
      const uncheck = rest.filter(v => !this.half_ids.includes(v))
      this.$emit('submit', this.checkedTeamIds, uncheck)
      this.show = false
    },
    handleClose (teamId) {
      const index = this.checkedTeamIds.findIndex(item => item === teamId)
      this.checkedTeamIds.splice(index, 1)
    }
  },
  mounted () {
    this.$store.dispatch('team/getTeamMembers')
  }
}
</script>
<style>
.history-popover {
  padding: 0 !important;
  border: 0 !important;
  box-shadow: var(--shadow) !important;
}
</style>
<style lang='scss' scoped>
@import '@/styles/common.scss';
.history-popover {
  padding: 0;
  background: #252F58;
  box-shadow: -2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: 0;
  .team-container {
    .team-header {
      height: 36px;
      line-height: 36px;
      padding: 0px 12px;
      box-shadow: inset 0 -1px 0 0 var(--color-border-1);
      display:flex;
      justify-content: space-between;
      .el-checkbox__inner {
        background: transparent;
        border-color: var(--color-border-1);
      }
      .el-checkbox__label {
        font-size: 14px;
        color: var( --color-unkown-text);
        font-weight: 400;
      }
      .check-count {
        font-weight: 400;
        font-size: 12px;
        color: var( --color-unkown-text);
      }
    }
    .team-content {
      padding: 6px 0;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll_bar();
      .item {
        padding: 0 12px;
        &:hover {
          background-color:var(--color-bg-3);
        }
      }
      .el-checkbox {
        width: 100%;
        // .el-checkbox__inner {
        //   background: transparent;
        //   border-color: #868EC2;
        // }
        .el-checkbox__label {
          font-size: 12px;
          color: var( --color-unkown-text);
          font-weight: 400;
          width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
      .no-data {
        color: var(--color-text-1);
        text-align: center;
        font-weight: 400;
      }
    }
    .team-footer {
      height: 32px;
      box-shadow: inset 0 1px 0 0 var(--color-border-1);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 6px 12px;
      .btn {
        margin-left: 8px;
        display: inline-block;
        // background: rgba(83,126,243,0.20);
        border: 1px solid var(--color-priamry);
        border-radius: 2px;
        font-size: 12px;
        color: var(--color-priamry);
        width: 48px;
        text-align: center;
        height: 24px;
        line-height: 24px;
        cursor: pointer;
        &:nth-child(2) {
          background: #009e71;
          color: #fff;
        }
        &:nth-child(1) {
          border-color:#3f4251;
          color: var(--color-text-1);
        }
      }
    }
  }
}
.share-btn {
  height: 32px;
  // width: 130px;
  line-height: 32px;
  display: inline-block;
  vertical-align: bottom;
  border-radius: 4px;
  font-size: 14px;
  color: var(--color-text-2);
  padding-left: 10px;
  cursor: pointer;
  position: relative;
  .el-icon {
    position: relative;
    top:2px;
    transition: all .2s
  }
  .active {
    transform: rotateZ(180deg);
  }
  .el-tag {
    border: 0;
    color: var(--color-priamry);
    font-weight: 300;
    &:not(:last-child) {
      margin-right: 4px;
      background: var(--color-bg-3);
      color: var(--color-text-1);
      border: 1px solid var(--color-border-1);
    }
    &.hasSibling {
      // max-width: 80px;
      overflow: hidden;
      background: var(--color-bg-3);
      color: var(--color-text-1);
      border: 1px solid var(--color-border-1);
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      position: relative;
      padding-right: 16px;
      .el-tag__close {
        position: absolute;
        top: 2px;
        right: 0px;
      }
    }
    &:only-of-type {
      // max-width: 108px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      position: relative;
      // padding-right: 16px;
      background: var(--color-bg-3);
      color: var(--color-text-1);
      border: 1px solid var(--color-border-1);
      .el-tag__close {
        position: absolute;
        top: 2px;
        right: 0px;
      }
    }
  }
  .el-tag__close {
    background: #BABFD0;
    color: #555;
    &:hover {
      background: #ccc;
      color: #000;
    }
  }
  .arrow {
    position: absolute;
    right: 6px;
    top: 8px;
  }
  &:focus {
    outline: 0;
  }
  &.disable {
    border-color: #333E6B;
    pointer-events: none;
  }
}
</style>
